import { defineStore } from 'pinia'
import {useStorage} from "@vueuse/core";

export const quickcheckPropertyStore = defineStore('quickcheckProperty', {
    state() {
        return {
            buildingType: useStorage('buildingType', ''),
            constructionYear: useStorage('constructionYear', 0),
            livingSpace: useStorage('livingSpace', 0),
            numberOfRooms: useStorage('numberOfRooms', 0),
            energyDemand: useStorage('energyDemand', 0),
            energyEfficiencyClass: useStorage('energyEfficiencyClass', ''),
        }
    },
});