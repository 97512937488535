

import CustomButton from '@/common/button/CustomButton.vue';
import {defineComponent} from "vue";
import QuickcheckResult from "@/quickcheck/model/QuickcheckResult";
import ValidationStatus from "@/quickcheck/model/ValidationStatus";
import QuickcheckResultChart from "@/quickcheck/components/QuickcheckResultChart.vue";
import {quickcheckPropertyStore} from "@/quickcheck/model/currentQuickcheckProperty";
import User from "@/navigation/model/user";
import Config from "@/config";
import InfoIconComponent from "@/common/InfoIconComponent.vue";

interface Data {
  quickcheckResult: QuickcheckResult | null,
  chartInput: { data: { x: string, y: number, fillColor: string }[] }[],
  constructionYear: number,
  livingSpace: number,
  buildingType: string,
  numberOfRooms: number,
  energyDemand: number,
  percentageOfBetterRealEstate: number,
  percentageOfBetterOverallRealEstate: number,
  estimatedEnergyDemand: number,
  estimatedEnergyEfficiencyClass: string,
  loading: boolean,
  similarPropertiesAvailable: boolean,
  showInfoTooltipSimilar: boolean,
  showInfoTooltipAccuracy: boolean,
  tooltipPositionSimilarCSS: TooltipPositionCSS,
  tooltipPositionAccuracyCSS: TooltipPositionCSS,
  quickcheckPropertyValidity: {
    constructionYearValidity: ValidationStatus,
    livingSpaceValidity: ValidationStatus,
    numberOfRoomsValidity: ValidationStatus,
    energyDemandValidity: ValidationStatus,
  },
  currentUser: User | null,
}

interface TooltipPositionCSS {
  top: string,
  left: string,
}

export default defineComponent({
  components: {InfoIconComponent, CustomButton, QuickcheckResultChart},
  setup() {
    const currentQuickcheckPropertyStore = quickcheckPropertyStore()
    return {currentQuickcheckPropertyStore}
  },
  data(): Data {
    return {
      quickcheckResult: null,
      chartInput: [{
        data: [{
          x: '',
          y: 0,
          fillColor: ''
        }]
      }],
      constructionYear: 0,
      livingSpace: 0,
      buildingType: '',
      numberOfRooms: 0,
      energyDemand: 0,
      percentageOfBetterRealEstate: 0,
      percentageOfBetterOverallRealEstate: 0,
      estimatedEnergyDemand: 0,
      estimatedEnergyEfficiencyClass: '',
      loading: true,
      similarPropertiesAvailable: false,
      showInfoTooltipSimilar: false,
      showInfoTooltipAccuracy: false,
      tooltipPositionSimilarCSS: {
        top: '',
        left: '',
      },
      tooltipPositionAccuracyCSS: {
        top: '',
        left: '',
      },
      quickcheckPropertyValidity: {
        constructionYearValidity: ValidationStatus.OK,
        livingSpaceValidity: ValidationStatus.OK,
        numberOfRoomsValidity: ValidationStatus.OK,
        energyDemandValidity: ValidationStatus.OK,
      },
      currentUser: null,
    };
  },
  methods: {
    async fetchUser() {
      if (!this.currentUser) {
        this.currentUser = await this.$ports.nav.getAuthenticatedUser();
      }
    },
    async goToPropertyRegistration() {
      await this.$router.push({
        name: 'property-registration',
      });
    },
    goToLogin() {
      window.location.href = Config.loginUrl;
    },
    toggleTooltipTouch(similar: boolean) {
      if (similar) {
        if (this.showInfoTooltipSimilar) {
          this.showInfoTooltipSimilar = false;
        } else {
          this.showTooltip(true);
        }
      } else {
        if (this.showInfoTooltipAccuracy) {
          this.showInfoTooltipAccuracy = false;
        } else {
          this.showTooltip(false);
        }
      }

    },
    showTooltip(similar: boolean) {
      if (similar) {
        this.showInfoTooltipSimilar = true;

        this.$nextTick(() => {
          let infoIconX = 0;
          let infoIconY = 0;
          let infoTooltipHeight = 0;
          let infoTooltipWidth = 0;

          if (this.$refs.infoIconSimilar && this.$refs.infoTooltipSimilar) {
            let infoIcon: HTMLElement = this.$refs.infoIconSimilar as HTMLElement;
            let infoTooltip: HTMLElement = this.$refs.infoTooltipSimilar as HTMLElement;
            infoIconX = infoIcon.getBoundingClientRect().x + (infoIcon.getBoundingClientRect().width / 2);
            infoIconY = infoIcon.getBoundingClientRect().top;
            infoTooltipHeight = infoTooltip.getBoundingClientRect().height;
            infoTooltipWidth = infoTooltip.getBoundingClientRect().width;
            infoIconX -= infoTooltipWidth / 2;
            if (infoIconX < 0) {
              infoIconX = 0;
            }
          }

          this.tooltipPositionSimilarCSS = {
            top: (infoIconY - infoTooltipHeight) + 'px',
            left: infoIconX + 'px',
          };
        });
      } else {
        this.showInfoTooltipAccuracy = true;

        this.$nextTick(() => {
          let infoIconX = 0;
          let infoIconY = 0;
          let infoTooltipHeight = 0;
          let infoTooltipWidth = 0;

          if (this.$refs.infoIconAccuracy && this.$refs.infoTooltipAccuracy) {
            let infoIcon: HTMLElement = this.$refs.infoIconAccuracy as HTMLElement;
            let infoTooltip: HTMLElement = this.$refs.infoTooltipAccuracy as HTMLElement;
            infoIconX = infoIcon.getBoundingClientRect().x + (infoIcon.getBoundingClientRect().width / 2);
            infoIconY = infoIcon.getBoundingClientRect().top;
            infoTooltipHeight = infoTooltip.getBoundingClientRect().height;
            infoTooltipWidth = infoTooltip.getBoundingClientRect().width;
            infoIconX -= infoTooltipWidth / 2;
            if (infoIconX < 0) {
              infoIconX = 0;
            }
          }

          this.tooltipPositionAccuracyCSS = {
            top: (infoIconY - infoTooltipHeight) + 'px',
            left: infoIconX + 'px',
          };
        });
      }
    },
    hideTooltip(similar: boolean) {
      if (similar) {
        this.showInfoTooltipSimilar = false;
      } else {
        this.showInfoTooltipAccuracy = false;
      }
    },
    isConstructionYearValid(constructionYearValidity: ValidationStatus): boolean {
      return constructionYearValidity === ValidationStatus.OK ||
          constructionYearValidity === ValidationStatus.MISSING;
    },
    isLivingSpaceValid(livingSpaceValidity: ValidationStatus): boolean {
      return livingSpaceValidity === ValidationStatus.OK ||
          livingSpaceValidity === ValidationStatus.MISSING;
    },
    isEnergyDemandValid(energyDemandValidity: ValidationStatus): boolean {
      return energyDemandValidity === ValidationStatus.OK ||
          energyDemandValidity === ValidationStatus.MISSING;
    },
    isNumberOfRoomsValid(numberOfRoomsValidity: ValidationStatus): boolean {
      return numberOfRoomsValidity === ValidationStatus.OK ||
          numberOfRoomsValidity === ValidationStatus.MISSING;
    },
    async requestQuickcheck(): Promise<void> {
      this.loading = true;
      this.currentQuickcheckPropertyStore.buildingType = this.buildingType;
      this.currentQuickcheckPropertyStore.livingSpace = this.livingSpace;
      this.currentQuickcheckPropertyStore.constructionYear = this.constructionYear;
      this.currentQuickcheckPropertyStore.numberOfRooms = this.numberOfRooms;
      this.currentQuickcheckPropertyStore.energyDemand = this.energyDemand;

      this.quickcheckResult = await this.$ports.quickcheck.requestQuickcheck({
        livingSpace: this.livingSpace,
        buildingType: this.buildingType,
        constructionYear: this.constructionYear,
        numberOfRooms: this.numberOfRooms,
        energyDemand: this.energyDemand,
      });
      this.loading = false;
      if (!isNaN(this.quickcheckResult.frequencies[0])) {
        this.similarPropertiesAvailable = true;
        this.estimatedEnergyDemand = Math.round(this.quickcheckResult.energyDemand * 10) / 10;
        this.percentageOfBetterRealEstate = Math.round(this.quickcheckResult.percentageOfBetterSimilarObjects * 10) / 10;
        this.percentageOfBetterOverallRealEstate = Math.round(this.quickcheckResult.percentageOfBetterOverallObjects * 10) / 10;
        this.estimatedEnergyEfficiencyClass = this.calculateEnergyEfficiencyClass();
      } else {
        this.similarPropertiesAvailable = false;
      }
    },
    validateInput() {
      this.quickcheckPropertyValidity = this.$ports.quickcheck.validateQuickcheckProperty({
        buildingType: this.buildingType,
        constructionYear: this.constructionYear,
        livingSpace: this.livingSpace,
        numberOfRooms: this.numberOfRooms,
        energyDemand: this.energyDemand,
      });
    },
    async submitData() {
      if (this.quickcheckPropertyValidity.constructionYearValidity === ValidationStatus.MISSING) {
        this.quickcheckPropertyValidity.constructionYearValidity = ValidationStatus.ERROR;
      }
      if (this.quickcheckPropertyValidity.livingSpaceValidity === ValidationStatus.MISSING) {
        this.quickcheckPropertyValidity.livingSpaceValidity = ValidationStatus.ERROR;
      }
      if (this.quickcheckPropertyValidity.numberOfRoomsValidity === ValidationStatus.MISSING) {
        this.quickcheckPropertyValidity.numberOfRoomsValidity = ValidationStatus.ERROR;
      }

      if (this.quickcheckPropertyValidity.constructionYearValidity !== ValidationStatus.ERROR &&
          this.quickcheckPropertyValidity.livingSpaceValidity !== ValidationStatus.ERROR &&
          this.quickcheckPropertyValidity.numberOfRoomsValidity !== ValidationStatus.ERROR) {

        await this.requestQuickcheck();
      }
    },
    calculateEnergyEfficiencyClass() {
      if (this.estimatedEnergyDemand < 30) {
        return 'A+';
      }
      if (this.estimatedEnergyDemand < 50) {
        return 'A';
      }
      if (this.estimatedEnergyDemand < 75) {
        return 'B';
      }
      if (this.estimatedEnergyDemand < 100) {
        return 'C';
      }
      if (this.estimatedEnergyDemand < 130) {
        return 'D';
      }
      if (this.estimatedEnergyDemand < 160) {
        return 'E';
      }
      if (this.estimatedEnergyDemand < 200) {
        return 'F';
      }
      if (this.estimatedEnergyDemand < 250) {
        return 'G';
      }
      return 'H';
    }
  },
  async created() {
    this.livingSpace = this.currentQuickcheckPropertyStore.livingSpace;
    this.buildingType = this.currentQuickcheckPropertyStore.buildingType;
    this.constructionYear = this.currentQuickcheckPropertyStore.constructionYear;
    this.numberOfRooms = this.currentQuickcheckPropertyStore.numberOfRooms;
    this.energyDemand = this.currentQuickcheckPropertyStore.energyDemand;
    await this.requestQuickcheck();
    await this.fetchUser();
  },
});

