import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_apexchart = _resolveComponent("apexchart")!

  return (_ctx.quickcheckResult)
    ? (_openBlock(), _createBlock(_component_apexchart, {
        key: 0,
        width: "600",
        type: "bar",
        options: _ctx.options,
        series: _ctx.series
      }, null, 8, ["options", "series"]))
    : _createCommentVNode("", true)
}