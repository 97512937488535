
import {defineComponent} from "vue";
import QuickcheckResult from "@/quickcheck/model/QuickcheckResult";

export default defineComponent({
  props: {
    quickcheckResult: {
      type: Object as () => QuickcheckResult
    },
  },
  data() {
    return {
      options: {
        chart: {
          type: 'bar'
        },
        yaxis: {
          title: {
            text: 'Häufigkeit in Prozent'
          }
        },
        xaxis: {
          title: {
            text: 'Energiebedarf in kWh/m²/a',
          }
        },
        plotOptions: {
          bar: {
            dataLabels: {
              position: 'top',
            }
          },
        },
        dataLabels: {
          style: {
            colors: ['#000']
          },
          offsetY: -20,
        },
        subtitle: {
          text: 'Verteilung der Energiebedarfe ähnlicher Immobilien',
          align: 'center',
          offsetY: -0,
          style: {
            fontSize: 15,
          }
        }

      },
      series: [{
        name: 'Prozentanteil',
        data: [{
          x: '',
          y: 0,
          fillColor: ''
        }]
      }],
    };
  },
  methods: {
    constructChartInputObject(): { x: string, y: number, fillColor: string }[] {
      const energyDemand: number = this.quickcheckResult!.energyDemand;
      let classRanges: number[] = this.quickcheckResult!.classRanges;
      let frequencies: number[] = this.quickcheckResult!.frequencies;

      // Trim starting and ending classes with probabilities of under 0.1%
      const lowerEndTrim = this.checkFrequenciesLowerEnd(frequencies);
      const higherEndTrim = this.checkFrequenciesHigherEnd(frequencies);
      if (lowerEndTrim !== 0 || higherEndTrim !== 0) {
        classRanges = classRanges.slice(lowerEndTrim, frequencies.length - higherEndTrim);
        frequencies = frequencies.slice(lowerEndTrim, classRanges.length - higherEndTrim);
      }

      const currentResultColor: string = this.determineCurrentResultColor(energyDemand);
      let chartInput: { x: string, y: number, fillColor: string }[] = [];
      const classLabels: string[] = this.buildClassLabels(classRanges);

      for (let i = 0; i < frequencies.length; i++) {
        chartInput.push({
          x: classLabels[i],
          y: Math.round(frequencies[i] * 1000) / 10,
          fillColor: this.isDemandInClassRange(energyDemand, classRanges[i], classRanges[i + 1]) ? currentResultColor : '#475a4a'
        });
      }
      return chartInput;
    },
    checkFrequenciesLowerEnd(frequencies: number[]): number {
      let trimCount = 0;
      for (let i = 0; i < frequencies.length; i++) {
        if (frequencies[i] < 0.001) {
          trimCount++;
        } else {
          break;
        }
      }
      return trimCount;
    },
    checkFrequenciesHigherEnd(frequencies: number[]): number {
      let trimCount = 0;
      for (let i = frequencies.length-1; i >= 0; i--) {
        if (frequencies[i] < 0.001) {
          trimCount++;
        } else {
          break;
        }
      }
      return trimCount;
    },
    trimNumbersArray(array: number[], lowerTrimCount: number, higherTrimCount: number): number[] {
      array = array.slice(lowerTrimCount, -higherTrimCount);

      return array;
    },
    determineCurrentResultColor(energyDemand: number) {
      if (energyDemand < 75) {
        return '#7fcf11';
      }
      if (energyDemand < 130) {
        return '#ffbf0f';
      }
      if (energyDemand < 200) {
        return '#ff7005';
      }
      return '#ff0305';
    },
    isDemandInClassRange(energyDemand: number, classRangeLower: number, classRangeHigher: number): boolean {
      return classRangeLower < energyDemand && energyDemand < classRangeHigher;
    },
    buildClassLabels(classRanges: number[]): string[] {
      let classLabels: string[] = [];
      for (let i = 0; i < classRanges.length; i++) {
        classLabels.push('' + classRanges[i] + ' - ' + classRanges[i + 1]);
      }
      return classLabels;
    },
  },
  watch: {
    quickcheckResult: {
      handler() {
        this.series.pop();
        this.series.push({
          name: 'Prozentanteil',
          data: this.constructChartInputObject(),
        });
      },
      deep: true
    }
  },
});

